import { FooterBackGround, FooterText } from "./styles";

const Footer = () => {
  return (
  
    <FooterBackGround>
      <FooterText>2022</FooterText>
    </FooterBackGround>
    
  );
};

export default Footer;
